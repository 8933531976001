<template>
    <div id="resources">
    <transition name="slide-fade" mode="out-in">
    <div class="mcontainer" v-if="this.$route.name === 'ApprovalResource' ">
        <div class="flex justify-between relative mb-5">
            <div class="flex-1">
                <h2 class="text-lg font-semibold"> Waiting Approval </h2>
                <p>Resource akan ditampilkan di halaman resources setelah mendapat approval dari admin.</p>
            </div>
        </div>
        <div class="lg:flex lg:space-x-12">
            <div class="lg:w-3/4">
                <div class="px-1 py-3">
                    <div class="uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-2 uk-grid-small uk-grid" v-if="loading">
                    <CardVideo v-for="resource in resources" :key="resource.id" :data="resource" />
                    </div>
                    <div v-if="!loading">
                        <div class="row">
                            <div class="col-md-4 loading-space" v-for="index in 6" :key="index">
                            <content-placeholders :rounded="true">
                                <content-placeholders-img />
                                <content-placeholders-heading />
                            </content-placeholders>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading_other">
                        <div class="row">
                            <div class="col-md-4 loading-space" v-for="index in 3" :key="index">
                            <content-placeholders :rounded="true">
                                <content-placeholders-img />
                                <content-placeholders-heading />
                            </content-placeholders>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center mt-6" v-if="loadmore_show">
                        <a href="javascript:void(0)" @click="loadmore()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                            Load more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view/>
    </transition>
    <b-modal v-model="category.form.show" :title="category.form.title" ok-title="Submit" :hide-footer="true">
        <form @submit.prevent="">
            <b-form-group>
                <b-form-input
                    v-model="category.form.data.title"
                    placeholder="Add a title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group align="right">
                <div>
                    <b-button v-if="category.form.data.id" variant="danger" class="mr-2" @click="deleteCategory">
                        Delete
                    </b-button>
                    <b-button variant="primary" type="submit" @click="submitCategory" size="sm">
                        Submit
                    </b-button>
                </div>
            </b-form-group>
        </form>
    </b-modal>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import CardVideo from '@/components/CardVideo'
    import axios from 'axios'

    export default {
        name: 'Resources',
        computed: {
            ...mapState('auth', { datauser: 'user' }),
            ...mapState('categories', {
                dataCategory: state => state.data,
                dataTags: state => state.tags
            })
        },
        components: {
            CardVideo
        },
        data () {
            return {
                checkedCategory: [],
                checkedTags: [],
                loading: true,
                resources: [],
                filter: {
                    search: ''
                },
                category: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            title: '',
                            type: 'news'
                        }
                    }
                },
                loading_other: false,
                loadmore_show: true,
                page: 1
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            ...mapActions('categories', {
                _createCategory: 'create',
                _updateCategory: 'update',
                _deleteCategory: 'delete',
                _fetchCategory: 'fetch'
            }),
            fetchSuggestion () {
                const $this = this
                this.loading = false
                this.page = 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('resource?search=' + this.filter.search + '&page=' + this.page + '&limit=12&category=' + categories + '&tags=' + tags).then(function (res) {
                    $this.resources = res.data.result.data
                    if ($this.resources.length < 12) {
                        $this.loadmore_show = false
                    }
                    $this.loading = true
                })
            },
            async fetchData () {
                this.loading = false
                try {
                    const res = await axios.get('resource?waiting_approval=1&page=' + this.page + '&limit=12')
                    this.resources = res.data.result.data
                    if (this.resources.length < 12) {
                        this.loadmore_show = false
                    }
                    this.loading = true
                    this._fetchCategory({ type: 'resource' })
                } catch (e) {
                    console.error(e)
                }
            },
            loadmore () {
                this.loading_other = true
                const $this = this
                this.page = this.page + 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('resource?waiting_approval=1&search=' + this.filter.search + '&page=' + this.page + '&limit=3&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.resources.push(...response.data.result.data)
                    $this.loading_other = false
                    if (response.data.result.data.length === 0) {
                        $this.loadmore_show = false
                    }
                })
            },
            submitCategory () {
                this.category.form.loading = true
                const { id, title, type } = this.category.form.data
                const promise = id ? this._updateCategory({ id, data: { title, type } }) : this._createCategory({ title, type })
                promise.then(() => {
                    this._fetchCategory({ type: 'resource' })
                    this.category.form.show = false
                    this.category.form.data = { id: '', title: '' }
                })
                    .finally(() => {
                        this.category.form.loading = false
                    })
            },
            addCategory () {
                this.category.form.title = 'Add Category'
                this.category.form.show = true
                this.category.form.data.id = ''
                this.category.form.data.title = ''
                this.category.form.data.type = 'resource'
            },
            editCategory (item) {
                this.category.form.title = 'Edit Category'
                this.category.form.show = true
                this.category.form.data = { ...item }
            },
            deleteCategory () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.category.form.loading = true
                        const { id } = this.category.form.data
                        this._deleteCategory(id)
                            .then(() => {
                                this._fetchCategory({ type: 'resource' })
                                this.category.form.show = false
                                this.category.form.data = { id: '', title: '' }
                                this.$swal(
                                    'Deleted!',
                                    'Your category has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.category.form.loading = false
                            })
                    }
                })
            },
            tagClass (tag) {
                const isSelected = this.checkedTags.includes(tag)
                return {
                    active: isSelected,
                    '': !isSelected
                }
            },
            selectTags (tag) {
                const index = this.checkedTags.findIndex(t => t === tag)
                if (index >= 0) {
                    this.checkedTags.splice(index, 1)
                } else {
                    this.checkedTags.push(tag)
                }
                this.fetchSuggestion()
            }
        }
    }
</script>
